import type { ClassValue } from "clsx";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * A utility function to contruct classNames conditionally and join Tailwind CSS classes without style conflicts.
 * @remarks It uses the {@link https://github.com/lukeed/clsx|clsx} and {@link https://github.com/dcastil/tailwind-merge|tailwind-merge} packages.
 * @param classNames - The classnames to be conditionally joined.
 * @returns The joined classnames.
 */
export function cn(...classNames: ClassValue[]) {
  return twMerge(clsx(classNames));
}
