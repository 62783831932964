import type { LinkProps } from "@remix-run/react";
import { Link as RemixLinkComponent, useHref } from "@remix-run/react";

// This component is used to generate the correct link component depending on the context (Storybook or Remix Router)
const Link = ({ children, to, ...props }: LinkProps) => {
  try {
    const _href = useHref(to); // used to throw error
    return (
      <RemixLinkComponent to={to} {...props}>
        {children}
      </RemixLinkComponent>
    );
  } catch (_error) {
    return (
      <a {...props} href={(to as string) || ""}>
        {children}
      </a>
    );
  }
};

export default Link;
