import { cn } from "~/utils/classnames";
import "./Loader.css";
import type { LoaderProps } from "./Loader.types";
import LoaderIcon from "~/assets/icons/loader.svg?react";

const Loader = ({ size = "custom", className }: LoaderProps) => (
  <LoaderIcon className={cn("Loader", `Loader--${size}`, className)} />
);

export default Loader;
