import { cloneElement } from "react";
import Link from "~/components/Link";
import "./Button.css";
import type { ButtonProps } from "./Button.types";
import Loader from "~/components/Loader";
import { cn } from "~/utils/classnames";

const Button = ({
  as = "button",
  type = "button",
  variant = "primary",
  size = "md",
  label,
  IconComponent,
  iconPosition = "left",
  disabled = false,
  className = "",
  iconClassName = "h-5 w-5",
  href = "",
  onClick,
  isLoading,
  "aria-label": ariaLabel,
  children,
  ...props
}: ButtonProps) => {
  const ButtonTag = as === "button" ? ("button" as React.ElementType) : Link;

  return (
    <ButtonTag
      className={cn(
        "Button",
        `Button--${variant}`,
        `Button--${size}`,
        disabled ? "Button--disabled" : null,
        isLoading ? "Button--loading" : null,
        iconPosition === "right" ? "flex-row-reverse" : null,
        className
      )}
      type={as === "button" ? type : undefined}
      to={as === "a" ? href : undefined}
      onClick={(e: Event) => {
        if (disabled) {
          e.preventDefault();
        }
        if (onClick) {
          onClick(e);
        }
      }}
      disabled={disabled}
      aria-label={ariaLabel || label}
      {...props}
    >
      {isLoading ? (
        <Loader size="sm" className="mx-4" />
      ) : (
        <>
          {IconComponent
            ? cloneElement(IconComponent, {
                className: cn("Button-icon", iconClassName),
              })
            : null}
          {label}
          {children}
        </>
      )}
    </ButtonTag>
  );
};

export default Button;
